import { graphql, PageProps } from "gatsby";
import * as React from "react";
import { Layout } from "../components/Layout";
import parse from "html-react-parser";
import { ProseContainer } from "../components/ProseContainer";

export const PreviewChapter: React.VFC<
  PageProps<{ wpPage: { title: string; content: string } }>
> = (props) => {
  const { wpPage } = props.data;
  return (
    <Layout headerVariation="small" title={wpPage.title}>
      <ProseContainer>
        <h1>{wpPage.title}</h1>
        {parse(wpPage.content)}
      </ProseContainer>
    </Layout>
  );
};

export default PreviewChapter;

export const pageQuery = graphql`
  query PreviewPageQuery {
    wpPage(id: { eq: "cG9zdDo1NQ==" }) {
      title
      content
    }
  }
`;
